import App from './App.svelte';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const app = new App({
  target: document.body,
  props: {},
});

export default app;
